:root{
    --headerFont: 5rem;
    --buttonFont: 2.5rem;
    --smallFont:1.9rem;
    --yellow:#faee49;
    --lightgreen: #52a853;
    --yarokehe:rgba(8,61,4,0.75);

}

.but h3{
    margin-top: 6%;
    color: white;
    font-family: "Rubik Distressed", system-ui;
    font-size: var(--buttonFont);
    font-weight: 400;
    font-style: normal;
    text-align: center;
}

.but p{
    margin-top: 0%;
    color: white;
    font-family: "Rubik Distressed", system-ui;
    font-size: var(--smallFont);
    font-weight: 400;
    font-style: normal;
    text-align: center;
}
.but p span{
    color: var(--yellow);
}
.but h4{
    margin-top: 0%;
    margin-bottom: 2%;
    color: white;
    font-family: "Rubik Distressed", system-ui;
    font-size: 4rem;
    font-weight: 400;
    font-style: normal;
    text-align: center;
}
.but .command4{
    color: var(--yellow);
    font-size: var(--headerFont);
}

.button-container {
    display: flex;
    justify-content: center;
    background-color: transparent;
    
}
.but  a{
    text-decoration: none;
}
button{
    background-color: transparent;
    box-shadow: 0px 1px 13px 6px rgba(17,255,0,0.69);
    -webkit-box-shadow: 0px 1px 13px 6px rgba(17,255,0,0.69);
    -moz-box-shadow: 0px 1px 13px 6px rgba(17,255,0,0.69);
    border: none;
margin-bottom: 3rem;
margin-top: 2rem;
cursor: pointer;
}

button span {
    color: var(--lightgreen);
    font-size: var(--buttonFont);
    font-family: "Rubik Distressed", system-ui;
    text-shadow: -3px 0px 10px rgba(17,255,0,0.5);

}
.fork h4{
    margin-top: 0%;
    margin-bottom: 2%;
    color: white;
    font-family: "Rubik Distressed", system-ui;
    font-size: 3rem;
    font-weight: 400;
    font-style: normal;
    text-align: center;
}

.fork p{
    margin-top: 0%;
    color: white;
    font-family: "Rubik Distressed", system-ui;
    font-size: 1.3rem;
    font-weight: 400;
    font-style: normal;
    text-align: center;
}
.fork .opposite{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 3rem;
    
}
.fork .opposite1{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-right: 9svw;
    gap: 11svw;
    margin-top: 3rem;
    margin-bottom: 2rem;
}
.fork .opposite p{
display: block;
margin-left: auto;
margin-right: auto;
max-width: 30%;
color:var(--lightgreen) ;
}
.fork .opposite span{
    color: var(--yellow);
}
.fork .opposite1 p{
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 30%;
    color:var(--lightgreen) ;
    }
    .fork .opposite1 span{
        color: var(--yellow);
    }
    .fork .bottomfork{
        color: var(--lightgreen);
    }
    .fork .bottomfork span{
        color: var(--yellow);
    }
    .fork .bottomfork{
        color: var(--lightgreen);
    }
    .fork .bottomfork i{
        color: white;
    }
    .fork .opposite i{
        color: white;
    }
    .fork .opposite{
        gap: 2rem;
    }
    .fork .opposite1{
        gap: 2rem;
    }
    .fork .opposite1 i{
        color: white;
    }
    .fork .all{
        text-align: center;
        font-family: "Rubik Distressed", system-ui;
        color: white;
        font-size: var(--smallFont);
    }
    .fork .promise{
        color: var(--yellow);
        font-family: "Rubik Distressed", system-ui;
        font-size: 3rem;
        font-weight: 400;
        font-style: normal;
        text-align: center;
    }
    .fork .prom{
        color: var(--lightgreen);
        margin-bottom: 3rem;
        text-shadow: -3px 0px 10px rgba(17,255,0,0.5);
        font-family: "Rubik Distressed", system-ui;
        font-size: var(--smallFont);
        font-weight: 400;
        font-style: normal;
        text-align: center;
    }
  
    .love {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center; /* Ensure text inside the .dad div is centered */
    }
    
    .love .dad {
        display: flex;
        flex-direction: row;
        margin: 0 auto; /* Center the .dad div */
        max-width: 80%; /* Ensure .dad doesn't exceed the width of its container */
        width: 100%;
        gap: 5rem; /* Optional: add space between the child divs */
    }
    
    .love .dad h4 {
        color: white;
        font-family: "Rubik Distressed", system-ui;
        font-size: 3rem;
        font-weight: 400;
        font-style: normal;
        text-align: center;
    }
    .love .dad span{
        color: var(--yellow);
    }
    
    .love .dad p {
        margin-top: 0%;
        color: white;
        font-family: "Rubik Distressed", system-ui;
        font-size: 1.3rem;
        font-weight: 400;
        font-style: normal;
        text-align: center;
    }
    .love .dad i{
        color: var(--lightgreen);
    font-size: var(--buttonFont);
    font-family: "Rubik Distressed", system-ui;
    text-shadow: -3px 0px 10px rgba(17, 255, 0, 0.5);
    }
    .love .dad .pips{
        box-shadow: 0px 1px 13px 6px var(--yellow);
        -webkit-box-shadow: 0px 1px 13px 6px var(--yellow);
        -moz-box-shadow: 0px 1px 13px 6px var(--yellow);
        max-width: 75%;
        margin-right: auto;
        margin-left: auto;
    
    }
    .love .dad .pipsh{
        box-shadow: 0px 1px 13px 6px rgba(17,255,0,0.69);
    -webkit-box-shadow: 0px 1px 13px 6px rgba(17,255,0,0.69);
    -moz-box-shadow: 0px 1px 13px 6px rgba(17,255,0,0.69);
        max-width: 100%;
        margin-right: auto;
        margin-left: auto;
    }
   
    .us h4{
        color: var(--yellow);
       
    font-family: "Rubik Distressed", system-ui;
    font-size: 4rem;
    font-weight: 400;
    font-style: normal;
    text-align: center;
    }
    .us p{
        margin-top: 0%;
        color: var(--lightgreen);
        text-shadow: -3px 0px 10px rgba(17,255,0,0.5);
        font-family: "Rubik Distressed", system-ui;
        font-size: var(--smallFont);
        font-weight: 400;
        font-style: normal;
        text-align: center;
    }
    .us .about{
        display: flex;
        flex-direction: row;
        margin: 0 auto; /* Center the .dad div */
        max-width: 80%; /* Ensure .dad doesn't exceed the width of its container */
        width: 100%;
        gap: 5rem; /* Optional: add space between the child divs */
    }
    .us .about img{
        max-width: 30%;
        display: block;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 0rem;
    }
    .us .about .img1{
        max-width: 29%;
        display: block;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 0rem;
    }
    .us .about h3{
        color: var(--yellow);
    font-family: "Rubik Distressed", system-ui;
    font-size: var(--buttonFont);
    font-weight: 400;
    font-style: normal;
    text-align: center;
    }

    .us .about p{
        color: white;
        font-family: "Rubik Distressed", system-ui;
        font-size: 1.5rem;
        max-width: 40svw;
        font-weight: 400;
        font-style: normal;
        text-align: center;
    }
    .us .aboutbottom{
        color: white;
        margin-top: 5rem;

        font-family: "Rubik Distressed", system-ui;
        font-size: var(--smallFont);
        font-weight: 400;
        font-style: normal;
        text-align: center;
    }
    .us .aboutbottom span{
    color: var(--yellow);
    }

    .video-carousel-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 50%;
        height: 50svh;
        margin: 0 auto;
      }
      
      .video-container {
        position: relative;
        width: 100%;
        height: 100%;
      }
      
      .video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: none;
      }
      
      .video.active {
        display: block;
      }
      
      .carousel-buttons {
        display: flex;
        margin-top: 10px;
      }
      
      .carousel-button {
        margin: 0 5px;
        padding: 5px 10px;
        border: none;
        border-radius: 5px;
        background-color: #ccc;
        cursor: pointer;
      }
      
      .carousel-button.active {
        background-color: #333;
        color: white;
      }

      


       /* Style for the image carousel */
.carousel-container {
    position: relative;
    width: 100%;
    max-width: 60rem; /* Adjust as needed */
    height: 50svh; /* Set height to 40svh */
    display: block;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    margin-top: 3rem;
  }
  
  .carousel-image {
    display: block;
    width: 100%;
    height: 100%; /* Ensure images fill the container */
    object-fit: contain; /* Maintain aspect ratio and cover the container */
  }
  
  /* Button styles */
  .carousel-container .carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0.5rem 1rem;
    color: white;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    border: none;
    cursor: pointer;
    z-index: 1; /* Ensure buttons are on top */
  }
  
  .carousel-button:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
  
  .carousel-button.prev {
    left: 10px; /* Adjust positioning */
  }
  
  .carousel-button.next {
    right: 10px; /* Adjust positioning */
  }
  
  /* Indicators */
  .carousel-indicators {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 8px;
  }
  
  .carousel-indicator {
    width: 10px;
    height: 10px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    cursor: pointer;
  }
  
  .carousel-indicator.active {
    background-color: white;
  }
      



    
    @media (max-width: 768px) {
        .love .dad {
            flex-direction: column;
        }
        .us .about {
            flex-direction: column;
        }
        .but h3{
            font-size: 26px;
            padding-left: 2%;
            padding-right: 2%;
        }
        .but p{
            font-size: 26px;
            padding-left: 2%;
            padding-right: 2%;
        }
        .but h4{
            font-size: 20px;
            padding-left: 2%;
            padding-right: 2%;
        }
        .fork h4{
            font-size: 20px;
            padding-left: 2%;
            padding-right: 2%;
        }
        .fork .opposite{
            flex-direction: column;
            max-width: 90%;
            margin-left: auto;
            margin-right: auto;
        }
        .fork .opposite p{
            max-width: 95%;
            text-align: right;
        }
        .fork .opposite p i{
            font-size: 1.5rem;
        }
        .fork .opposite1{
            flex-direction: column;
            max-width: 90%;
            margin-left: auto;
            margin-right: auto;
        }
        .fork .opposite1 .attention{
            margin-right: none;
            text-align: right;
            position: relative;
            right: -22%;
        }
        .fork .opposite1 p{
            max-width: 95%;
            text-align: right;
        }
        .fork .promise{
            font-size: 26px;
            padding-left: 2%;
            padding-right: 2%;
        }
        .fork .prom{
            font-size: 20px;
            padding-left: 2%;
            padding-right: 2%;
        }
        .love .dad h4{
            font-size: 26px;
            padding-left: 2%;
            padding-right: 2%;  
        }
        .love .dad p{
            font-size: 20px;
            padding-left: 2%;
            padding-right: 2%;  
        }
        .us h4{
            font-size: 26px;
            padding-left: 2%;
            padding-right: 2%;  
        }
        .us p{
            font-size: 20px;
            padding-left: 2%;
            padding-right: 2%;  
        }
        .us .about p{
            font-size: 20px;
            padding-left: 2%;
            padding-right: 2%; 
            max-width: 90%; 
            margin-right: auto;
            margin-left: auto;
        }
        .us .aboutbottom{
            font-size: 20px;
            padding-left: 7%;
            padding-right: 7%;   
        }
        .us .about img{
            max-width: 15rem;

        }
        .us .about .img1{
            max-width: 15rem;
        }

        .carousel-container{
            max-width: 90svw;
        }
    }
