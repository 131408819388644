@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@400;500;700&family=Poppins:wght@200;300;400;500;600;700&family=Road+Rage&family=Rubik&family=Rubik+Dirt&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@400;500;700&family=Poppins:wght@200;300;400;500;600;700&family=Road+Rage&family=Rubik&family=Rubik+Dirt&family=Rubik+Distressed&display=swap');

:root{
    --headerFont: 5rem;
    --buttonFont: 2.5rem;
    --smallFont:1.5rem;
    --yellow:#faee49;
    --lightgreen: #52a853;
    --yarokehe:rgba(8,61,4,0.75);

}
html{
    scroll-behavior: smooth;
}
body{
    background-color: #000000;
    margin: 0%;
    padding: 0%;
    background: rgb(250,238,73);
background: linear-gradient(180deg, rgba(250,238,73,1) -2%, rgba(0,0,0,1) 5%);
}

*{
    direction: rtl;
}
header{
    padding-top: 0%;
    margin-top: 0%;
    
}
.backg{
    margin-top: 0%;
    padding-top: 0%;
    
}
 .logo{
    max-width: 10%;
    float: right;
    position: absolute;
    top: 2%;
}
h1{
   
    color: var(--lightgreen);
    /* text-shadow: -3px 0px 10px rgba(17,255,0,0.5);    */
     font-family: "Rubik Dirt", system-ui;
    font-size: var(--headerFont);
    font-weight: 400;
    font-style: normal;
    text-align: center;
}

header h2{
    color: var(--lightgreen);
    font-family: "Rubik Distressed", system-ui;
    font-size: var(--buttonFont);
    font-weight: 400;
    font-style: normal;
    text-align: center;
}
header p{
    color: var(--yellow);
    font-family: "Rubik Distressed", system-ui;
    font-size: var(--smallFont);
    font-weight: 400;
    font-style: normal;
    text-align: center;
}
header img{
    max-width: 25%;
    display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
header  .pips{
    box-shadow: 0px 1px 13px 6px var(--yellow);
    -webkit-box-shadow: 0px 1px 13px 6px var(--yellow);
    -moz-box-shadow: 0px 1px 13px 6px var(--yellow);
    max-width: 62%;
    margin-right: auto;
    margin-left: auto;

}
header .p1{
    margin-top: 2svh;
    color: var(--lightgreen);
    font-family: "Rubik Distressed", system-ui;
    font-size: var(--smallFont);
    font-weight: 400;
    font-style: normal;
    text-align: center; 
    
}
header .p1 span{
    color: var(--yellow);
}

header .paps{
    max-width: 80%;
    margin-top: 10svh;
    display: flex;
    flex-direction: row;
    gap: 8rem;
    margin-left: auto;
    margin-right: auto;
}
header .paps .paps1{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}
header .paps .first1{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    max-width: 100%;
    height: 60svh;
    box-shadow: 0px 1px 13px 6px rgba(17,255,0,0.69);
    -webkit-box-shadow: 0px 1px 13px 6px rgba(17,255,0,0.69);
    -moz-box-shadow: 0px 1px 13px 6px rgba(17,255,0,0.69);
}
header .paps .first1 h3{
    margin-right: 5%;
    margin-left: 5%;
    margin-bottom: 0%;
    text-align: center;
    color: var(--yellow);
    font-family: "Rubik Distressed", system-ui;
    font-size: var(--buttonFont);
    font-weight: 400;
    font-style: normal;
}
header .paps .first1 p{
    margin-right: 5%;
    margin-left: 5%;
    padding-bottom: 0%;
    font-size: 1.15rem;
    color: white;
    max-width: 50ch;
    
}
header .paps .first1 button{
    margin-top: 0%;
 display: block;
 margin-left: auto;
 margin-right: auto;
}
header .paps .first1 button i{
    font-size: 3rem;
}
header .paps .first button i{
    font-size: 3rem;
}
header .paps .first1 a{
    text-decoration: none;
}
header .paps .first button{
    display: block;
    position: relative;
    top: 8%;
    margin-left: auto;
    margin-right: auto;
   }
   header .paps .first a{
    text-decoration: none;
}
header .paps .first{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    max-width: 100%;
    height: 60svh;
    box-shadow: 0px 1px 13px 6px rgba(17,255,0,0.69);
    -webkit-box-shadow: 0px 1px 13px 6px rgba(17,255,0,0.69);
    -moz-box-shadow: 0px 1px 13px 6px rgba(17,255,0,0.69);
}
header .paps .first h3{
    margin-right: 5%;
    margin-left: 5%;
    text-align: center;
    color: var(--yellow);
    font-family: "Rubik Distressed", system-ui;
    font-size: var(--buttonFont);
    font-weight: 400;
    font-style: normal;
}
header .paps .first p{
    margin-right: 5%;
    margin-left: 5%;
    font-size: 1.2rem;
    color: white;
    max-width: 50ch;
    
}

header i{
    color: aliceblue;
    font-size: 5rem;
    margin-bottom: 1.2rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

@media (min-width: 1750px) and (max-width:1850px){
    header .paps .first1 h3{
        font-size: 1.2rem;
    }
    header .paps .first1 p{
        font-size: 1rem;
    }
    header .paps .first1 button{
      position: relative;
      top: -1rem;
      max-width: 60%;

    }
    header .paps .first1 button span{
        font-size: 1rem;
    }
    header .paps .first h3{
        font-size: 1.2rem;
    }
    header .paps .first p{
        font-size: 1rem;
        margin-bottom: 0%;
    }
    header .paps .first button{
        position: relative;
        top: 11%;
        max-width: 60%;
    }
    header .paps .first button span{
        font-size: 1.2rem;    }
    header .paps .first button{
        top: 0%;
        margin-bottom:4rem;
    }
}
@media (min-width: 700px) and (max-width:1750px){
        header .paps .first1 h3{
            font-size: 1.2rem;
        }
        header .paps .first1 p{
            font-size: 1rem;
        }
        header .paps .first1 button span{
            font-size: 0.9rem;
        }
        header .paps .first h3{
            font-size: 1.2rem;
        }
        header .paps .first p{
            font-size: 1rem;
            margin-bottom: 0%;
        }
        header .paps1:nth-child(1) button{
        position: relative;
        top: 5rem;
        }
        header .paps .first button span{
            font-size: 0.9rem;
        }
        header .paps .first button{
            top: 0%;
            margin-bottom:4rem;
        }
    
}

@media (max-width: 700px){
    body{
       
        background: rgb(250,238,73);
    background: linear-gradient(180deg, rgba(250,238,73,1) -2.5%, rgba(0,0,0,1) 3%);
    }
    header .pips{
        max-width: 85%;
    }
header h1{
    margin-top: 15%;
    font-size:32px ;
    padding-right: 10px;
    padding-left: 10px;
}
header h2{
    font-size: 28px;
    padding-right: 5px;
    padding-left: 5px;
}
header p{
    font-size: 20px;
    padding-right: 5px;
    padding-left: 5px;

}
header img{
    max-width: 30%;
}
header .p1{
    margin-top: 1svh;
    font-size: 20px;
    padding-right: 15px;
    padding-left: 15px;
   
}

header .paps {
flex-direction: column !important;  
justify-content: baseline; 
 max-width: 90%;
 margin-top: 7rem;
}
header .paps .first1{
    justify-content: space-between;
    min-height: 60svh;
}
header .paps .first1 h3{
    font-size: 25px;
}
header .paps .first1 p{
  font-size: 20px;
}

    header .paps i{
        margin-top: 0rem;
        
    }
    header .paps .arrow{
        position: relative;
        top: -2.2rem;
    }
    header .paps .first1{
        margin-top: 2rem;
    }
    header .paps .first{
        margin-top: 2rem;
        min-height: 60svh;

    }
    header .paps .first h3{
        font-size: 25px;
    }
    header .paps .first p{
        font-size: 20px;
    }
}
@media (max-width: 400px){
    header .paps .first1{
        height: 60%;
    }
    header .paps .first{
        height: 60%;
    }
}