:root{
    --headerFont: 5rem;
    --buttonFont: 2.5rem;
    --smallFont:1.9rem;
    --yellow:#faee49;
    --lightgreen: #52a853;
    --yarokehe:rgba(8,61,4,0.75);

}

.end p{
    margin-top: 3%;
    color: white;
    font-family: "Rubik Distressed", system-ui;
    font-size: var(--smallFont);
    font-weight: 400;
    font-style: normal;
    text-align: center;
}
.end p span{
    color: var(--yellow);
}
.formd{
    max-width:60rem ;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0px 1px 13px 6px rgba(17,255,0,0.69);
    -webkit-box-shadow: 0px 1px 13px 6px rgba(17,255,0,0.69);
    -moz-box-shadow: 0px 1px 13px 6px rgba(17,255,0,0.69);
}
.formd h3{
    padding-top: 3%;
    color: white;
    font-family: "Rubik Distressed", system-ui;
    font-size: var(--buttonFont);
    font-weight: 400;
    font-style: normal;
    text-align: center;
}
.formd p{
    margin-top: 3%;
    color: white;
    font-family: "Rubik Distressed", system-ui;
    font-size: var(--smallFont);
    font-weight: 400;
    font-style: normal;
    text-align: center;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
}


.formd form {
    padding-top: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: auto;
    margin-right: auto;
    max-width: 50%;
    height: 400px !important; /* Force the height */
}
 .formd form iframe div.xpWfed{
    display: none;
}
.formd form input{
    outline: none;
    height: 1.5rem;
    background-color: transparent;
    border: none;
    font-size: 1.5rem;

    border-bottom: #52a853 solid 1px;
    color: #52a853;
    font-family: "Rubik Distressed", system-ui;
font-weight: 400;
}

.formd form ::placeholder{
    color: #52a853;
    font-size: 1.5rem;
    font-family: "Rubik Distressed", system-ui;
font-weight: 400;
}

.home-sci{
    margin-top: 2rem;
}
.home-sci > a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background: transparent;
    border: 2px solid var(--lightgreen);
    border-radius: 50%;
    font-size: 20px;
    color: var(--lightgreen);
    text-decoration: none;
    transition: 0.5s ease;
    animation: slideLeft 1s ease forwards;
    animation-delay: calc(0.2s * var(--i));
    margin: 30px 15px 30px 0;
  }
  
  .home-sci > a:hover {
    background: var(--lightgreen);
    color: #081b29;
    box-shadow: 0 0 20px var(--lightgreen);
  }
  footer{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 2rem;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  footer img{
max-width: 10%;
  }
  .idan a{
    text-decoration: none;
  }
  .idan a span{
    color: var(--lightgreen);
    text-decoration: none;
    font-family: "Rubik Distressed", system-ui;
    font-size: var(--smallFont);
    font-weight: 400;
    font-style: normal;
    text-align: center;
    font-size: 1rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: 700px) and (max-width: 1700px){
    .formd{
        max-width:50rem ;
    }
    footer{
        max-width: 100%;
    }
    footer img{
        max-width: 11%;
          }
}

@media (max-width: 700px) {
    .end p{
        font-size: 20px;
            padding-left: 7%;
            padding-right: 7%;
    }
    .end .formd{
        max-width: 80%;
    }
    .end .formd p{
        font-size: 20px;
            padding-left: 7%;
            padding-right: 7%;
    }
    .end .formd h3{
        font-size: 26px;
            padding-left: 7%;
            padding-right: 7%;
    }
    .end .formd form input{
        font-size: 12px;
            
    }
    .end .formd form input::placeholder{
        font-size: 12px;
            
    }
    .end .formd  form{
        height: 300px !important;
    }
    .end .formd  form button span{
        padding-top: 1rem !important;
    font-size: 1.7rem;
    text-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    text-decoration: none;
    }
    .home-sci > a{
        width: 20px;
        height: 20px;
    }
    footer img{
        max-width: 5rem;
        height: 5rem;
        padding-top: 1.5rem;
    }
    .idan a span{
        font-size: 0.7rem;
    }
}